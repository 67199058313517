<template>
    <div class="fa1">
        <div>&nbsp;&nbsp;<input type="checkbox" v-model="checked" @click="inchode"> 已完成{{ newnum }}/全部{{ num }}</div>
        <div><button @click="del">清楚已完成任务</button></div>
    </div>
</template>

<script>
export default {
    name: 'Project删除组件',

    data() {
        return {
            num: 0,// 总条数
            checked: false,//全选盒子的初始值
            newnum: 0,//选中条数
            window
        };
    },
    mounted() {
        this.ck()
    },
    created() {
        this.ck()
        // 接收cnum的值给到checked进而判断它的选中状态
        $bus.$on('cnum', val => {
            this.checked = val
        })

    },
    methods: {
        // 给全选框添加点击事件
        inchode() {
            // 这里通过点击事件进行传值，注意判断条件要与正常思维相反（点击的时候checked会发生变化）
            if (this.checked == false) {
                $bus.$emit('chhh', true)
            } else {
                $bus.$emit('chhh', false)
            }
        },
        ck() {
            // 接收总数量
            $bus.$on('numdd', (val) => {
                this.num = val
            })
            // 接收选中的条数
            $bus.$on('numnew', (val) => {
                this.newnum = val
            })
        },
        del() {
            // 删除直接传false值，这里传的false值相当于未选中数据的状态
            let arr = false
            $bus.$emit('cheak', arr)
        }

    },
};
</script>

<style lang="less" scoped>
.fa1 {
    width: @white;
    height: 60px;
    line-height: 60px;
    display: flex;
    flex-direction: column;
    align-content: space-between;
    flex-wrap: wrap;
    bottom: 10px;
}



div {
    button {
        background-color: @butcolor;
        border: none;
        height: 36px;
        width: 120px;
        border-radius: 5px;
        color: white;
    }

    input[type="checkbox"] {
        -webkit-appearance: none;
        appearance: none;
        vertical-align: middle;
        margin-top: 0;
        background: #fff;
        border: #999 solid 1px;
        border-radius: 3px;
        min-height: 16px;
        min-width: 16px;
        position: relative;
        cursor: pointer;
    }

    input[type="checkbox"]:checked {
        background: #1982e5;
        border: none;
    }

    input[type=checkbox]:checked::after {
        content: '';
        top: 3px;
        left: 3px;
        position: absolute;
        background: transparent;
        border: #fff solid 2px;
        border-top: none;
        border-right: none;
        height: 6px;
        width: 10px;
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
}
</style>