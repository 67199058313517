<template>
    <div class="search11">
        <search class="sea1"></search>
        <Tttt></Tttt>
        <eedel class="eedel"></eedel>
    </div>
</template>

<script>
import search from "./src/任务添加栏.vue";
import Tttt from "./src/任务栏.vue";
import eedel from "./src/删除组件.vue";
export default {
    name: 'ProjectApp',

    data() {
        return {

        };
    },

    mounted() {

    },

    methods: {

    },
    components: {
        search,
        Tttt,
        eedel,
    }
};
</script>

<style lang="less" scoped>
.search11 {
    width: 650px;
    outline: 1px solid @wicolor;
    margin-top: 20px;
    border-radius: 5px;
    padding-bottom: 10px;
}

.sea1 {
    margin-top: 0px;
}
</style>