<template>
    <div class="fa1">
        <div class="son">
            <input type="text" placeholder="请输入你的任务名称，按回车键结束" v-model="strName" @keydown.enter="ck1">
        </div>
    </div>
</template>

<script>
export default {
    name: 'Project任务添加栏',

    data() {
        return {
            strName: '',
        };
    },

    mounted() {

    },

    methods: {
        ck1() {
            // 判断输入框是否为空或者超过最大输入上限
            if (this.strName != '' && this.strName.length <= 30) {
                // 定义一个新数组newNum接收任务名字strName
                let newName = {
                    name: '',
                    // 默认初始值为false
                    checked: false,
                }
                // 赋值
                newName.name = this.strName
                // 传给兄弟组件
                $bus.$emit('send', newName)
                // 传送完成后清空输入框
                this.strName = ''
            } else {
                alert('！！！！！！任务名称禁止为空或超过最大字数限制30字！！！！！！任务名称禁止为空或超过最大字数限制30字！！！！！！任务名称禁止为空或超过最大字数限制30字！！！！！！任务名称禁止为空或超过最大字数限制30字！！！！！！任务名称禁止为空或超过最大字数限制30字！！！！！！任务名称禁止为空或超过最大字数限制30字！！！')
            }
        }
    },
};
</script>

<style lang="less" scoped>
.fa1 {
    width: @white+2px;
    height: 50px;
    overflow: hidden;
}

.son {
    width: @white ;
    margin: 10px auto;
    outline: 1px solid @wicolor;
    border-radius: 5px;
}

input {
    border: none;
    outline: none;
    width: @white;
    height: 35px;
}
</style>