<template>
    <div class="hhhh">
        <p v-for="(item, index) in arr">
            &nbsp;&nbsp;<input type="checkbox" v-model="item.checked">
            &nbsp; {{ item.name }}
            <button @click="del(index)">删除</button>
        </p>
    </div>
</template>

<script>

export default {
    name: 'Project任务栏',

    data() {
        return {
            arr: [{ name: '菲', checked: true }, { name: '晓', checked: false }, { name: '李', checked: true }, { name: '陈', checked: true },],
            indexNum: 0,//总条数待传参
            newNum: 0,//选中条数等待传参数据
            window,
        };
    },

    mounted() {
        // 页面初始化调用
        this.ck()
        this.num()
    },
    watch: {
        // 监听arr发生变化，后面的一切操作围绕这个来写
        arr: {
            // 深度监听
            deep: true,
            handler(newval) {
                this.num()
                // arr发生变化就传到本地
                window.localStorage.setItem('users', JSON.stringify(newval))
                // 定义一个变量接收数量的变化
                let cnum = 0
                for (let index = 0; index < newval.length; index++) {
                    const element = newval[index];
                    // 通过判断arr下面的checked值等于true让cnum++等于被选中的个数
                    if (element.checked == true) {
                        cnum++
                        // 如果cnum的值=arr的长度那么就给全选传对应的值
                        if (cnum == newval.length) {
                            $bus.$emit('cnum', true)
                        } else {
                            $bus.$emit('cnum', false)
                        }
                    }
                }

            }


        },

    },
    created() {
        // 从本地拿数据，赋值给arr
        let str = localStorage.getItem('users')
        this.arr = JSON.parse(str)

        this.num()
        // 接收未勾选状态下删除后的数据
        $bus.$on('cheak', val => {
            let arrcopy = []
            for (let index = 0; index < this.arr.length; index++) {
                const element = this.arr[index];
                if (element.checked == val) {
                    // 未选中的数据赋值到新数组里
                    arrcopy.push(element)
                }
            }
            // arr勾选删除更新数据在此处
            this.arr = arrcopy
        })
        // 接收全选状态的checked值
        $bus.$on('chhh', val => {
            for (let index = 0; index < this.arr.length; index++) {
                const element = this.arr[index];
                // 全选的checked值与任务子元素的checked值平起平坐
                element.checked = val
            }
        })
    },
    methods: {

        ck() {
            // 接收来自任务添加组件的数据到arr数组对象里面
            $bus.$on('send', (val) => {
                // unshift从数组前面添加
                this.arr.unshift(val)
            })


        },
        num() {
            // 传送总条数
            this.indexNum = this.arr.length
            $bus.$emit('numdd', this.indexNum)
            // 传送选中的数量通过a1变量的变化而更新
            let a1 = 0
            for (let index = 0; index < this.arr.length; index++) {
                const element = this.arr[index];
                if (element.checked == true) {
                    a1++
                }
            }
            // for循环外接收a1的值
            this.newNum = a1
            // 传给兄弟
            $bus.$emit('numnew', this.newNum)

        },
        // 单独删除每条数据根据传参的index值使用splice方法进行删除
        del(el) {
            this.arr.splice(el, 1)
        }
    },
};
</script>

<style lang="less" scoped>
div {
    margin-top: 10px;
    width: @white;
    // height: 150px;
    padding-bottom: 50px;

    input[type="checkbox"] {
        -webkit-appearance: none;
        appearance: none;
        vertical-align: middle;
        margin-top: 0;
        background: #fff;
        border: #999 solid 1px;
        border-radius: 3px;
        min-height: 16px;
        min-width: 16px;
        position: relative;
        cursor: pointer;
    }

    input[type="checkbox"]:checked {
        background: #1982e5;
        border: none;
    }

    input[type=checkbox]:checked::after {
        content: '';
        top: 3px;
        left: 3px;
        position: absolute;
        background: transparent;
        border: #fff solid 2px;
        border-top: none;
        border-right: none;
        height: 6px;
        width: 10px;
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
}

p {
    height: 35px;
    width: @white;
    line-height: 35px;
    outline: 1px solid @wicolor;
}

p:after {
    display: block;
    content: "clear";
    height: 0;
    clear: both;
    overflow: hidden;
    visibility: hidden;
}

button {
    background-color: @butcolor;
    height: 30px;
    width: 50px;
    float: right;
    margin-right: 10px;
    border: none;
    color: #fff;
    border-radius: 5px;
    margin-top: 2.5px;
    display: none;
}

p:hover button {
    display: block;
}
</style>