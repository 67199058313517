import Vue from 'vue'
import App from './组件传值通信vue/App.vue'

Vue.config.productionTip = false
// 全局过滤器 
Vue.filter('mynum', (text) => {
  return text + 3
})
import mearddd from "../src/01_组件通信/son/son1.vue";
Vue.component('mearddd', mearddd)//全局组件
window.$bus = new Vue()
new Vue({
  render: h => h(App),
  directives: {}
}).$mount('#app')

